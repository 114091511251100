<template>
  <v-app>
    <v-container fluid full-height style="margin-top:60px;margin-bottom: 90px;;"  class="app_bg">
      <center><v-img src="/img/tico_photo.png" style="max-width: 92px;margin-bottom: 25px;"></v-img></center>
      <v-form class="imgup" name="imgup" @submit.prevent="place_image">

          <v-row  style='margin-top:-15px; '>
         
          <v-col cols="12" style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
            <v-btn v-if="url" rounded class="button_new_task" type="submit"
              >{{$t('btn_send')}}</v-btn
            >
          </v-col>
        </v-row>
        <v-row fluid v-if="foto_btn_display" style='margin-top:10px;'>
        
          <v-col cols="12" style="display: flex;justify-content: center;flex-direction: column;align-items: center;">
            <v-btn
              @click="clickHandler"
              rounded
              class="button_new_task"
              v-if="!url"
            >
              

              {{$t('btn_take')}}
            </v-btn>

            <v-btn
              @click="clickHandler"
              rounded
              class="button_new_task"
              v-if="url"
            >
              {{$t('btn_retake')}}
            </v-btn>
            <input
              id="file-upload"
              type="file"
              ref="file"
              accept="image/*"
              capture
              @change="onFileChange"
            />
          </v-col>
         
        </v-row>


        <v-row v-if="tdata['type'] == 'ex_photo_timer'&&foto_btn_display!=true">
          <v-col cols="12" align="center">
            
            <p v-html="tdata['inact_message']">
             
            </p>
            
            
            
          </v-col>
        </v-row>

        <v-row class="tp_write" v-if="tdata['type'] == 'ex_photo_timer'&&foto_btn_display!=true">
          <v-col cols="12" align="center">
            <span class="tp_write link"  @click="write_tp=true">Написати Торговому Представнику</span>
            
            
          </v-col>
        </v-row>

        <v-row class="tp_write_small" v-if="tdata['type'] == 'ex_photo_timer'&&foto_btn_display==true">
          <v-col cols="12" align="center">
           
            <span class="tp_write_small link"  @click="write_tp=true">Написати Торговому Представнику</span>
            
            
          </v-col>
        </v-row>






        

        <v-row>
          <v-col cols="1"></v-col>
          <v-col cols="10" id="preview">
            <img v-if="url" :src="url" contain />
          </v-col>
          <v-col cols="1"></v-col>
        </v-row>











        <v-card class="rounded-lg transparent_to_background" elevation="10" v-if="tdata['type'] != 'ex_photo_code'" style="padding:14px;" >
          <v-row v-if="tdata['type'] == 'custom_extra_photo'">
          
          <v-col cols="12" align="center">
          
            <p v-html="tdata['description']" style="font-size:18px;width:100%; text-align: justify;"></p>

            
            
            
            
          </v-col>
        </v-row>
        <v-row min-height>
          <v-col cols="12" align="center">
<!--             
            <p>
              <b>{{ tdata['header'] }}</b>
            </p> -->
            
            <p v-if="tdata['type'] == 'sku_photo'" v-html="tdata['sku_list']"></p>
            <p style="text-align:left;margin:5px;" v-if="this.selected_level!=null" v-html="this.tdata['levels'][this.selected_level].full_desc"></p>
           
            <p v-if="tdata['type'] == 'mlevel_lay_photo'"> <span><br><b>Обов'язковий асортимент:</b><br><br></span></p>
              <!-- <v-row v-for="(sku,i) in skus_core_range" :key="i" style="border-bottom:solid 2px grey">
                <v-col cols="6"><span v-html="sku['name']"></span></v-col>
                <v-col cols="6"><img :src="sku['img']" style="max-height: 140px;width:auto;border:solid black 0px;"></v-col>
              </v-row> -->


              <div style="display: flex;    flex-direction: row;    flex-wrap: wrap;margin-top: -40px;">
              <v-card  v-for="(sku,i) in skus_core_range" :key="i"  style="margin: 2.5%; width: 45%;border-radius: 20px; min-height: 225px;" class="transparent_to_background">
              <img :src="sku['img']" style="max-height:  135px;width:auto;border:solid black 0px;"><br><span v-html="sku['name']"></span>
              
              </v-card>
              </div>


            
          </v-col>
        </v-row>


        <v-row v-if="tdata['type'] == 'lay_photo'">
          <v-col cols="12" align="center">
            
            <!-- <p>
              <b>{{$t('rules_txt')}}</b>
            </p>
            <span class="link"  @click="rules_disp=true">{{$t('rules_lnk')}}</span>
            <br> -->
            <p>
              <v-progress-circular indeterminate v-if="!foto_btn_display"
                color="red">
                {{time}}
              </v-progress-circular>
            </p>
            
          </v-col>
        </v-row>

        </v-card>
        <v-card class="rounded-lg transparent_to_background" elevation="10" v-if="tdata['type'] == 'ex_photo_code'">
        <v-row v-if="tdata['type'] == 'ex_photo_code'">
          
          <v-col cols="12" align="center">
            <p>
              <b v-html="$tc('sku_rules_txt',rand_code)"></b>
            </p>

            <p v-html="tdata['description']" style="font-size:18px;width:100%;text-align: left;padding-left: 15px;padding-right: 10px;"></p>

            <p v-if="this.task_id==473">
              <b>Приклад правильного виконання:</b><br> 
              <a href="img/202210_sample1.jpg" target="_blank"><v-img src="img/202210_sample1.jpg" contain></v-img></a>
            </p>

            <p v-if="this.task_id==474">
              <b>Приклад правильного виконання:</b><br>
              <a href="img/202210_sample2.jpg" target="_blank"><v-img src="img/202210_sample2.jpg" contain></v-img></a>
            </p>
            
            <p v-if="this.task_id==622">
              <b>Дивіться приклад виконання:</b><br>
              <a href="img/202307_sample.png" target="_blank"><v-img src="img/202307_sample.png" contain></v-img></a>
            </p>

            <!-- <p>
              <b>{{$t('rules_extra_txt')}}</b>
            </p>
            <span class="link"  @click="rules_extra_disp=true">{{$t('rules_extra_lnk')}}</span>
            <br> -->
            
            
            
          </v-col>
        </v-row>
        </v-card> 

        
        

        

      
        

        <v-row v-if="tdata['type'] == 'lay_photo'">
          <v-col cols="12">
           <!-- <ass :da="da"  :gl="gl" :fm="fm"></ass> -->
          </v-col>
        </v-row>
      </v-form>


      <v-dialog v-model="rules_extra_disp">
        <v-card>
        <!-- <ass_pg :da="da"  :gl="gl" :fm="fm"></ass_pg> -->
        <v-row>
          <v-col cols="12">
            <span><center>Приклад фото</center><br></span>
            <a href="img/202210_sample1.jpg" target="_blank"><v-img src="img/202210_sample1.jpg" contain></v-img></a>
            <a href="img/202210_sample2.jpg" target="_blank"><v-img src="img/202210_sample2.jpg" contain></v-img></a>
          </v-col>

        </v-row>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="rules_extra_disp = false">{{$t('close')}}</span>
        </v-card-actions>
        </v-card>
      </v-dialog>





      <v-dialog v-model="rules_disp">
        <v-card>
        <!-- <ass_pg :da="da"  :gl="gl" :fm="fm"></ass_pg> -->
        <v-row>
          <v-col cols="12">
            <span><center>Приклад побудови фокуса на Jade Compact Purple</center><br></span>
            <a href="img/v1_1.PNG" target="_blank"><v-img src="img/v1_1.PNG" contain></v-img></a>
            <a href="img/v1_2.PNG" target="_blank"><v-img src="img/v1_2.PNG" contain></v-img></a>
          </v-col>

        </v-row>
        <v-row>
          <v-col cols="12">
            <span><center>Приклад побудови фокуса на West QS</center><br></span>
            <a href="img/v2_1.PNG" target="_blank"><v-img src="img/v2_1.PNG" contain></v-img></a>
            <a href="img/v2_2.PNG" target="_blank"><v-img src="img/v2_2.PNG" contain></v-img></a>
          </v-col>

        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="rules_disp = false">{{$t('close')}}</span>
        </v-card-actions>
        </v-card>
      </v-dialog>




      <v-dialog v-model="write_tp">
      <v-card>
        <v-card-title   style="background-color:orange;color:white;z-index:1;margin-bottom:20px;justify-content:center;text-align:center;">
          <!-- header -->
          {{$t('h_contact')}}  
        </v-card-title>
       
        <v-card-text>
          <v-row align="top" justify="center">
         <v-col cols="10" sm="4" md="4">
             <v-textarea
            outlined
            name="input-7-4"
            label=""
            value="Питання"
            v-model=req_tp_tp
          ></v-textarea>

          <v-btn primary large block type="submit" @click="save_request_tp"  class="primary_button">Надіслати</v-btn>
         </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="link" text @click="write_tp = false">   {{$t('close')}} </span>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="level_select_dialog" persistent fullscreen >
      <v-card class="app_bg" style="padding-top: 60px !important;">
      
        <div @click=" $router.push({ name:'Main'})" class="close_dialog"></div>
        <v-card-text>
          <center><v-img src="/img/level_select_image.png"  max-height="187px" contain center></v-img
            ></center>
          <v-card class="simple_card transparent_to_background" style="margin-top: 35px;">
            
              <v-row fluid justify="center" v-for="(item,index) in tdata['levels']" :key="index"  
              @click="selectLevel(index)"
              :class="{'selected-row': selected_level === index}"
              style="cursor: pointer;padding-top: 15px;padding-left: 15px;">
                    
                    
                    <v-col cols="8" v-html="item.short_desc" >        
                      
                    </v-col>
                    <v-col cols="4" style="display: flex;flex-direction: column;justify-content: center;align-items: center;" >   
                      <v-img src="/img/l_select_star.png" max-height="32px" style="margin-top: -8px;" contain center></v-img>    
                      
                      <div class="l_select_text">{{item.weight}} балів</div>
                    </v-col>
                 
              </v-row>            
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-row >
          <v-col cols="2">
           <v-switch style="transform:scale(1.3)"
        v-model="switch1"
        label=""
      ></v-switch>
          </v-col>
          <v-col cols="10">
          Попередній перегляд фото<br>
(потрібно вимкнути, якщо є проблеми із завантаженням фото)
 </v-col>
        </v-row>

    




    </v-container>
  </v-app>
</template>

<style lang="scss" scoped>
$switch-track-dense-height:28px ;
$switch-track-height:28px;
$switch-thumb-height:40px;
</style>

<style scoped>
input[type="file"] {
  display: none;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>


<script>
import axios from "axios";
import { API_LOCATION } from "../../config";
import $ from 'jquery';
// import ass from "../components/ass.vue";
// import ass_pg from "../components/ass_pg.vue";
export default {
  beforeRouteLeave (to, from, next) { 
    if(this.url)
    {
          const answer = window.confirm('Ви не надіслали фото! Дійсно бажаєте вийти з завдання?')
        if (answer) {
          next()
        } else {
          next(false)
        }
          console.log(to) 
          console.log(from) 
          console.log(next) 
    }
    else
    {
      next()
    }
    
    }
    
    ,
  components: {
    // eslint-disable-next-line vue/no-unused-components
    // ass,ass_pg
  },
  data() {
    return {
      rand_code:'',
      url: null,
      file: "",
      task: false,
      task_id: null,
      descr: "",
      header: "",
      tdata: [],
      da:false,
      gl:false,
      fm:false,
      foto_btn_display:false,
      rules_disp:false,
      rules_extra_disp:false,
      time:5,
      timer: null,
      g:{long:'',lat:'',coord_state:''},
      write_tp:false,
       req_tp_tp:'',
        apiurl:API_LOCATION,
        switch1:true,
        level_select_dialog:false,
        selected_level:null,
        skus_core_range:null
    }
  },
  mounted() {

  },
  methods: {
    selectLevel: function(index) {
      this.selected_level = index;
    },
    save_request_tp: function(){

            axios.post(this.apiurl,  { data: 'component_data',action: 'tpq_create_tp', 'question':this.req_tp_tp },{ headers: { 'content-type': 'application/x-www-form-urlencoded' } })
         .then(resp =>{
           
         if(resp.data=='OK'){ 
            //this.$store.commit('setSnack', 'Запит надіслано!')
            alert('Запит надіслано!');
            this.write_tp=false;
            this.req_tp_tp='';
            //this.$router.push({ name: 'Profile'})
          }
          })
            
        },
    get_user_rand_code: function(){
        axios
      .post(
        API_LOCATION,
        {
          
          action: "get_user_rand_code",
          task:this.task_id,
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {

              this.rand_code=resp.data;
              console.log('code:'+resp.data);
        
      });
    },

    startTimer() {
      this.timer = setInterval(() => {
        this.time--
        console.log(this.time);
      }, 1000)
    },
    stopTimer() {
      this.foto_btn_display=true
      clearTimeout(this.timer)
    },
    clickHandler() {
      this.$refs.file.click();
    },
    place_image: function () {
      let formData = new FormData();
      formData.append("action", "img_upload");
      formData.append("task", this.task_id);
      formData.append("rand_code", this.rand_code);
      formData.append("file", this.file);
      formData.append("longtitude", this.g.long);
      formData.append("latitude", this.g.lat);
      if(this.tdata['type'] == 'mlevel_lay_photo'||this.tdata['type'] == 'mlevel_sku_photo')
      {
        formData.append("choosen_level", this.selected_level);
      }

      console.log(this.file);
      axios
        .post(API_LOCATION, formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then((resp) => {
          console.log("SUCCESS!!");
          if (resp.data == "OK") {
            this.url=null;
            this.$router.push({ name: "Main" });
            // this.$router.push('/')
          }
          //alert(resp.data);
          console.log(resp.data);
        });
    },
    capture() {
        if(this.switch1==true){
          this.canvas = this.$refs.canvas;
          // eslint-disable-next-line no-unused-vars
          var context = this.canvas
            .getContext("2d")
            .drawImage(this.video, 0, 0, 640, 480);
          // eslint-disable-next-line no-undef
          this.captures.push(canvas.toDataURL("image/png"));
        }
    },

   
    onFileChange(e) {
      const file = e.target.files[0];
      this.file = this.$refs.file.files[0];
      console.log(e.target.files[0]);
        if(this.switch1==true){
          this.url = URL.createObjectURL(file);
        }
        else{
            this.url=true;
        }
      if((e.target.files[0].lastModified+100000)<Date.now())
      {
        //alert('MODIFIED:'+e.target.files[0].lastModified+"\nDate:"+Date.now());
        alert('Не з галереї 😁');
        this.url=null
      }
    },
    navOnSuccess(position) {
                 console.log('Latitude: '          + position.coords.latitude          + '\n' +
                        'Longitude: '         + position.coords.longitude         + '\n' +
                        'Altitude: '          + position.coords.altitude          + '\n' +
                        'Accuracy: '          + position.coords.accuracy          + '\n' +
                        'Altitude Accuracy: ' + position.coords.altitudeAccuracy  + '\n' +
                        'Heading: '           + position.coords.heading           + '\n' +
                        'Speed: '             + position.coords.speed             + '\n' +
                        'Timestamp: '         + position.timestamp                + '\n');
                        
                        
                        this.g.long = position.coords.longitude
                        this.g.lat = position.coords.latitude
                        this.g.coord_state='success'
                        console.log('long: '+this.g.long+': lat:'+this.g.lat)
                        //alert('long: '+this.g.long+': lat:'+this.g.lat)
                        console.log(this.g);

              },

              navOnError(error) {
                  console.log('NAV_FAIL: code: '    + error.code    + '\n' +
                        'message: ' + error.message + '\n')
              }
  },
  computed: {
   
    title() {
        return this.$t('title')
      }
  },
  watch:{
      title: function(){
        this.$store.dispatch("page_title_set", this.title);
      },
      time(time) {
      if (time === 0) {
        this.stopTimer()
      }
      },
      
      selected_level: function(){
        if(this.selected_level!=null){
          this.level_select_dialog=false;
          console.log(this.tdata['levels'][this.selected_level].full_desc)
        }
      }
  },
  created() {



      console.log(this.g);

      
              if (navigator.geolocation)
              {
              navigator.geolocation.getCurrentPosition(this.navOnSuccess, this.navOnError,{enableHighAccuracy:false,maximumAge:Infinity, timeout:60000})
               console.log('start_nav_detect')
              }
              else
              {
                console.log('geolocation_not_supported')
              }














    console.log(this.$route);
    switch(this.$store.getters.contract){
          case '50':
                  this.da=false;
                  this.gl=false;
                  this.fm=false;
                  break;
          case '70':
                  this.da=true;
                  this.gl=false;
                  this.fm=false;
                  break;
          case '85': 
                  this.da=true;
                  this.gl=true;
                  this.fm=false;
                  break;
          case '100': 
                  this.da=true;
                  this.gl=true;
                  this.fm=true;
                  break;
        }
    this.$store.dispatch("page_title_set", this.title);
    this.$store.dispatch("profileset");



        console.log(this.$route.params.routeparam);
    if (this.$route.params.routeparam) {
      this.task_id = this.$route.params.routeparam;
      this.task = true;
      axios
        .post(
          API_LOCATION,
          { data: "component_data", action: "load_task", id: this.task_id },
          { headers: { "content-type": "application/x-www-form-urlencoded" } }
        )
        .then((resp) => {
          this.tdata = JSON.parse(resp.data.data_json);
          this.skus_core_range=[]

          if(this.tdata['type'] == 'mlevel_lay_photo'||this.tdata['type'] == 'mlevel_sku_photo')
          {
            this.level_select_dialog=true;
            //this.selected_level=2;
            Object.entries(this.tdata['skus_core_range']).forEach(element => {
              console.log('list_num: '+element[0]+"\r\nname: "+element[1]['name']);
              
              this.skus_core_range.push({'id':element[0],'name':element[1]['name'],'img':element[1]['img']});



            });

          }

          if(this.tdata['type'] == 'lay_photo')
          {
           
            this.foto_btn_display=true;
            //this.startTimer();
          }
          else
          {
             this.get_user_rand_code();
            this.foto_btn_display=true;
          }

           if(this.tdata['type'] == 'ex_photo_timer')
          {
          //  let start=new Date(this.tdata['start_active'].replace(/-/g,'/').replace('T',' ').replace(/\..*|\+.*/,"")).getTime();
          //  let end=new Date(this.tdata['end_active'].replace(/-/g,'/').replace('T',' ').replace(/\..*|\+.*/,"")).getTime();


                  
          // var arr = this.tdata['start_active'].split(/[- :]/);
          // let start = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]).getTime();
          // console.log(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
               
          //  arr = this.tdata['end_active'].split(/[- :]/);
          // let end = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]).getTime();

            let start=new Date(this.tdata['start_active'].replace(" ", "T")).getTime();
           let end=new Date(this.tdata['end_active'].replace(" ", "T")).getTime();




           let now=new Date().getTime();
            console.log(start,now,end);
            /*
            alert(this.tdata['start_active']);
            alert(start);
            alert(now);
            alert(end);*/

            if(start<now&&end>now)         
             {
                this.foto_btn_display=true;
              }
              else{
                this.foto_btn_display=false;
              }

          }

          // this.descr = this.tdata.description_ru;
          // this.header = this.tdata.header_ru;

          const ex = resp.data.id_ex
          const moderate=resp.data.moderate
          const correct=resp.data.correct
          this.$store.dispatch("page_title_set", this.tdata['header']);
          if(ex!=null)
          {
            this.task = false;
            this.$router.push({ name: "Main" });
            if(moderate==1)
            {
              this.$emit('pushe', {header:'Повідомлення',text:'Завдання знаходиться на модерації.'});
            }
            else
            {
              
              var task_state=correct==1?'Успішно':'Помилка';
              this.$emit('pushe', {header:'Повідомлення',text:'Завдання вже виконано.<br>Статус: <b>'+task_state+'</b>'});
            }
          }
          console.log(this.tdata);
          $('.header_global .photo_header').html(this.tdata.header);
        });
    } else {
      this.task = false;
       this.$router.push({ name: "Main" });
    }
  },
};
</script>
<i18n>
{
  
  "ua":{
     "h_contact":"Надіслати повідомлення торговому представнику",
      "title":"Зробіть фото",
      "btn_take":"Зробити фото",
      "btn_retake":"Зробити нове фото",
      "btn_send":"Надіслати фото",
      "close":"Закрити",
      "rules_txt":"Увага! Продукція має бути розміщена згідно правил викладки",
      "rules_extra_txt":"Увага! Блок має бути запакований, а код нанесений відповідно прикладу.",
      "sku_rules_txt":"ВАШ УНІКАЛЬНИЙ КОД  <br><br><font style='color:#ed8000;font-size:40px;border: solid 3px black;padding:5px;'><b>{count}</b></font>",
      "rules_lnk":"Переглянути викладки",
      "rules_extra_lnk":"Переглянути приклад"
      

  }
}
</i18n>

<style>

.tp_write { 
  font-size: 26px;
    font-weight: bold;
    color: #ff9900;
  /*
  animation-name: myanimation;
  animation-duration: 5s;
  animation-iteration-count: infinite;*/
  
}


.tp_write_small { 
    color: #ff9900;
  /* animation-name: myanimation;
  animation-duration: 3s;
  animation-iteration-count: infinite;*/
  
}


@keyframes myanimation {
  0%   {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
  }
  50%  {
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -o-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2)
  }
  100%  {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
  }
}
</style>
<style lang="css" scoped>
.v-dialog__content--active{
  background-color: white;;
}
.selected-row {
  background-color: #e0e0e0; /* Highlight color for the selected row */
}
</style>